import { $ } from '@situation/setdesign.util';

const legend = '<div class="calendar-legend">Best Availability</div>';
$(document).one('TicketCalendar.init', (e, TicketCalendar, $on) => {
    $on(TicketCalendar.FILTER_DEFAULTS, (defaults) => {
        defaults.update({
            // set to the $tc-theme-name sass variable
            theme: 'purlie-victorious',
            'disable-default-analytics': true,
            'scroll-calendar': {
                instructionText: `<div>Scroll to find a performance</div>${legend}`,
            },
            'accordion-month': { innerLegend: legend },
            // add a custom arrow svg icon with class called .tc-accordion__toggle-custom
            'accordion-month-header': { arrowSvgName: 'icon-accordion-toggle' },
        });
    });

    $on('fc-view-render', (view, element) => {
        if (element.find('.tc-availability--best').length <= 0) {
            element.parents('.tc-accordion').addClass('tc-accordion--no-legend');
        }
    });

    TicketCalendar.api.events().done((events) => {
        const bestAvail = events.filter((event) => event.availability === 'best');
        if (bestAvail.length) {
            $('.ticket-calendar').addClass('ticket-calendar--has-best-avail');
        }
    });
});
