import { addFilter } from '@situation/setdesign.util';

/**
 * Modifies the default navigation text for the media carousel.
 * @param {Object} settings - The default settings object for the media carousel.
 * @return {Object} The modified settings object.
 *
 */
addFilter('media-carousel/owl-settings', (settings) => ({
    ...settings,
    navText: [
        '<svg><use xlink:href="#icon-arrow-left"></use></svg>',
        '<svg><use xlink:href="#icon-arrow-right"></use></svg>',
    ],
}));
